import { combineReducers } from 'redux';
import constants from './ConstantReducer';
import layout from './LayoutReducer';
import locale from './LocaleReducer';
import user from './UserReducer';
import config from './ConfigReducer';

export default combineReducers({
  user,
  config,
  constants,
  layout,
  locale,
});
