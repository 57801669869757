import { Button } from 'antd';
import Footer from 'components/Footer';
import DynamicHeader from 'components/Headers/DynamicHeader';
import React from 'react';
import styled from 'styled-components';

const GiftTerms = () => {
  return (
    <PageWrapper>
      <DynamicHeader />

      <section>
        <div className="section__content">
          <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>
            Gift Card Terms and Conditions
          </h2>
          <div className="store-block">
            <h3>Amazon</h3>
            <ol>
              <li>
                <b>Gift Cards; Amazon.sg Balance; Redemption.</b>
                <br />
                Amazon.sg Gift Cards are issued by Amazon Asia-Pacific Holdings
                Private Limited, a Singapore company, and include Amazon.sg Gift
                Cards that are automatically added to your Amazon.sg Balance
                (“Gift Cards”). Your Amazon.sg Balance displays the total
                remaining balance of all Gift Cards that have been applied to
                your Amazon.sg account but not yet applied to a purchase. We may
                refer to your Amazon.sg Balance as your “Amazon.sg Balance”,
                “Gift Card balance”, or “GC Balance”. To view your Amazon.sg
                Balance, visit Your Account on Amazon.sg. Purchases are deducted
                from your Amazon.sg Balance. Any unused Amazon.sg Balance will
                remain associated with your Amazon.sg account. If a purchase
                exceeds your Amazon.sg Balance, the remaining amount must be
                paid with another payment method.
                <br /> <br />
                Gift Cards may only be redeemed toward the purchase of eligible
                goods and services sold on www.amazon.sg. For purposes of these
                terms and conditions, “Amazon”, “we”, “us”, and “our” means
                Amazon Asia-Pacific Holdings Private Limited and its Affiliates.
                “Affiliate” means with respect to a party, any entity that
                directly or indirectly Controls, is Controlled by, or is under
                common Control with that party. “Control” means the possession,
                direct or indirect, of the power to direct or cause the
                direction of the management and policies of an entity, whether
                through the ownership of voting securities or otherwise.
                Eligible goods and services are subject to change in our sole
                discretion. Redemption of Gift Cards at any affiliated property
                is subject to change in our sole discretion.
              </li>
              <li>
                <b>Limitations.</b>
                <br />
                Your Amazon.sg Balance cannot be used to purchase other Gift
                Cards, prepaid open loop ecards, or certain third-party gift
                cards. <br />
                <br />
                Gift Cards cannot be reloaded; resold; used for payment outside
                of www.amazon.sg or its affiliated properties; used for
                unauthorised advertising, marketing, lotteries, promotional or
                commercial purposes, including to facilitate the resale or
                shipment of goods from Amazon; redeemed for more than face
                value; transferred for value; redeemed for cash; returned for a
                cash refund (except to the extent required by law); or used in a
                manner otherwise prohibited by our Amazon.sg Gift Card
                Restrictions and Prohibited Activities policy. No portion of
                your Amazon.sg Balance may be transferred to another Amazon.sg
                account. Neither your Amazon.sg Balance nor your Gift Cards may
                be applied to the purchase of goods or services at any
                Amazon-affiliated property outside of Singapore.
              </li>
              <li>
                <b>Risk of Loss.</b>
                <br />
                The risk of loss and title for Gift Cards pass to you upon our
                electronic transmission of the Gift Card to you. Gift Cards must
                be obtained from Amazon, and you are responsible for
                safeguarding your Amazon.sg Balance or Gift Card from
                unauthorised use. We are not responsible if any Gift Card is
                lost, stolen, or destroyed, or if your Amazon.sg Balance or any
                Gift Card is used without your permission. There are a variety
                of Gift Card scams that request payment by Gift Card. Amazon is
                not responsible for, and assumes no liability to you for, any
                unlawful conduct or fraud by any third party associated with any
                Gift Card.
              </li>
              <li>
                <b>
                  Use of Amazon.sg Balance or any Gift Card in Violation of
                  these Terms and Conditions.
                </b>
                <br />
                By using a Gift Card you agree to comply with these terms and
                conditions, and not to use a Gift Card in any manner that is
                misleading, deceptive, unfair, or otherwise harmful to Amazon or
                its customers. <br />
                <br />
                We reserve the right, without notice to you, to void Gift Cards
                (including as a component of your Amazon.sg Balance) without a
                refund, suspend or terminate customer accounts, suspend or
                terminate the ability to use our services, cancel or limit
                orders, and bill alternative forms of payment, if we suspect
                that a Gift Card is obtained, used, or applied to an Amazon.sg
                account (or your Amazon.sg Balance is applied to a purchase)
                fraudulently, unlawfully, or otherwise in violation of these
                terms and conditions.
              </li>
              <li>
                <b> Limitation of Liability.</b>
                <br />
                TO THE FULL EXTENT PERMISSIBLE BY LAW, WE MAKE NO WARRANTIES,
                EXPRESS OR IMPLIED, WITH RESPECT TO GIFT CARDS OR YOUR AMAZON.SG
                BALANCE, INCLUDING WITHOUT LIMITATION, ANY EXPRESS OR IMPLIED
                WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY, OR FITNESS
                FOR A PARTICULAR PURPOSE. TO THE FULL EXTENT PERMISSIBLE BY LAW,
                IN THE EVENT A GIFT CARD IS NON-FUNCTIONAL, YOUR SOLE REMEDY,
                AND OUR SOLE LIABILITY, WILL BE THE REPLACEMENT OF THAT GIFT
                CARD.
              </li>
              <li>
                <b> Disputes.</b>
                <br />
                Any dispute or claim relating in any way to Gift Cards or your
                Amazon.sg Balance will be resolved by binding arbitration,
                rather than in court. To begin an arbitration proceeding, you
                must send a letter requesting arbitration and describing your
                claim to Amazon Asia-Pacific Holdings Private Limited, Attn:
                Legal Department, 23 Church Street, Capital Square, 10th Floor,
                Singapore 049481. The arbitration will be conducted by the
                Singapore International Arbitration Centre (the “SIAC”) in
                accordance with its Arbitration Rules in effect at the time of
                your request (the “Rules”). The Rules are available at
                http://www.siac.org.sg/our-rules. To the extent of any
                inconsistency or conflict between the Rules and the provisions
                in these terms and conditions, the terms and conditions will
                prevail. Payment of all filing, administration, and arbitrator
                fees will be governed by the Rules. Amazon will reimburse those
                fees for claims totalling less than S$10,000 unless the tribunal
                determines the claims are frivolous. Likewise, Amazon will not
                seek attorneys’ fees and costs in arbitration unless the
                tribunal determines the claims are frivolous. The arbitration
                will take place in Singapore, and the language of arbitration
                shall be English. The arbitral tribunal shall comprise one
                arbitrator nominated jointly by Amazon and you, or failing such
                joint nomination, by the President of the Court of Arbitration
                of the SIAC. Any decision or award of the arbitral tribunal
                shall be final and binding upon the parties. The parties agree
                to waive (to the fullest extent permitted by law) any right of
                application or appeal to any court or tribunal of competent
                jurisdiction in connection with any question of fact or law
                arising in the course of any arbitration or from any arbitral
                award or order.
                <br />
                <br />
                We also both agree that you or we may bring suit in court to
                enjoin infringement or other misuse of intellectual property
                rights.
              </li>
              <li>
                <b> General Terms.</b>
                <br />
                The Amazon.sg Conditions of Use apply. No fees apply to Gift
                Cards. We reserve the right to require additional verification
                of your identity, Gift Card or account ownership, or provision
                of an additional payment instrument, before you are able to
                apply a Gift Card to your account or your Amazon.sg Balance to a
                purchase. <br />
                <br />
                When you receive or apply a Gift Card to your account, or your
                Amazon.sg Balance to a purchase, you agree that the laws of
                Singapore, without regard to principles of conflict of laws,
                will govern these terms and conditions and any dispute that may
                arise between you and Amazon related to your use of a Gift Card
                or your Amazon.sg Balance.
                <br />
                <br /> We reserve the right to change these terms and conditions
                without notice, from time to time at our sole discretion. All
                terms and conditions are applicable to the extent permitted by
                law. If any of these terms and conditions are deemed invalid,
                void, or for any reason unenforceable, that unenforceable term
                will be deemed severable and will not affect the validity and
                enforceability of any remaining terms and conditions.
              </li>
              <li>
                <b>Amazon.sg Balance Expiration.</b>
                <br />
                All Gift Cards are valid for ten (10) years from the date of
                issuance; they expire in accordance with their stated terms.
              </li>
            </ol>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.amazon.sg/gp/help/customer/display.html?nodeId=GNG9PXYZUMQT72QK"
            >
              Learn More
            </a>
          </div>

          <div className="store-block">
            <h3>Shopee (SHOPEE COIN CASHBACK VOUCHER)</h3>
            <ol>
              <li>
                Users may receive a voucher entitling them to additional Shopee
                Coins (the “Shopee Coin Cashback Voucher”) on eligible purchases
                on the Site. Upon the completion of a successful transaction
                where the Shopee Coin Cashback Voucher has been applied,
                additional Shopee Coins will be credited to a User’s Account
                based on the conversion rate as indicated in the terms and
                conditions of such Shopee Coin Cashback Voucher (“Additional
                Shopee Coins Rate”) or as determined by Shopee in its sole
                discretion. Shopee Coins credited to a User following the
                redemption of a Shopee Coin Cashback Voucher shall have the same
                terms and conditions (including in relation to expiry) as
                regular Shopee Coins.
              </li>
              <li>
                Shopee may allow Sellers to generate their own Shopee Coin
                Cashback Vouchers (each a “Seller Coin Cashback Voucher”), which
                Buyers may apply to eligible purchases from the Seller’s store.
                If you are a Seller that generates a Seller Coin Cashback
                Voucher, you will be deemed to have consented to the terms and
                conditions described in this Section.
              </li>
              <li>
                When generating a Seller Coin Cashback Voucher, the Additional
                Shopee Coins Rate will be determined by Seller at the Seller’s
                own discretion. Seller shall be solely responsible for the
                accuracy of the Additional Shopee Coins Rate.
              </li>
              <li>
                For each completed transaction where a Buyer successfully
                applies a Seller Coin Cashback Voucher, the Seller shall pay
                Shopee the value of the additional Shopee Coins credited to the
                Buyer (“Coin Cashback Price”). The Coin Cashback Price is
                calculated in Singapore Dollars as the number of additional
                Shopee Coins to be credited to the Buyer’s Account divided by
                100, rounded up to the nearest cent. The Coin Cashback Price is
                subject to GST (“Coin Cashback Tax Amount”), and the Seller is
                responsible for such Coin Cashback Tax Amount.
              </li>
              <li>
                Following the successful completion of a transaction, Shopee
                shall deduct the Coin Cashback Price and Coin Cashback Tax
                Amount from the Buyer’s Purchase Monies, and remit the balance
                to the Seller in accordance with Section 12.2. Shopee shall
                issue receipts or tax invoices for the Coin Cashback Price and
                Coin Cashback Tax Amount on request. For the avoidance of doubt,
                the Coin Cashback Price and Coin Cashback Tax Amount applies in
                addition to the Transaction Fee and Tax Amount under the Terms
                of Service.
              </li>
              <li>
                The Coin Cashback Price and Coin Cashback Tax Amount is strictly
                non-refundable.
              </li>
            </ol>
          </div>

          <div className="store-block">
            <h3>Lazada</h3>

            <ol>
              <li>
                <b>General</b>
                <ol>
                  <li>
                    By using digital voucher(s) or gift card(s) bought from
                    Lazada (the “Digital Good(s)”), you agree to be bound by
                    these digital vouchers and gift cards terms and conditions
                    (the “Digital Goods Terms”), in addition to the Terms of Use
                    for the lazada.sg website and/or the LAZADA mobile app,
                    which are incorporated by reference into these Digital Goods
                    Terms.
                  </li>
                  <li>
                    For the avoidance of doubt, capitalised terms used and not
                    defined herein have the same meaning as given to such terms
                    in the Terms of Use. In the event of a conflict between the
                    provisions of the Digital Goods Terms and the Terms of Use,
                    the provisions of the Digital Goods Terms shall prevail.
                  </li>
                  <li>
                    Customers should refer to the Digital Good or its Product
                    Details Page (as defined below) for any Seller specific
                    terms and conditions and expiry dates.
                  </li>
                </ol>
              </li>
              <li>
                <b> Loss or Theft of Digital Goods</b>
                <ol>
                  <li>
                    Once purchased, the Digital Good is the responsibility of
                    the Customer and any Digital Goods, including accompanying
                    pin codes, that are lost or stolen shall not be replaced.
                  </li>
                </ol>
              </li>
              <li>
                <b> Redeeming Digital Goods</b>
                <ol>
                  <li>
                    Instructions on how to redeem a Digital Good may be made
                    available on the product details page of the relevant
                    Digital Good, under lazada.sg website (the “Product Details
                    Page”).{' '}
                  </li>
                </ol>
              </li>
              <li>
                <b>Customer’s Obligations</b>
                <ol>
                  <li>
                    Digital Goods can only be redeemed within the period of time
                    specified on the Product Details Page;{' '}
                  </li>
                  <li>
                    Digital Goods cannot be redeemed on certain black-out dates
                    or promotion dates set by the Seller or Lazada;{' '}
                  </li>
                  <li>
                    The Customer shall not use Digital Good(s) or permit the use
                    of Digital Good(s) in any manner which may adversely affect
                    other Customers’ use of Digital Good(s) and/or any other
                    Service provided to such Customers, as Lazada may reasonably
                    determine;{' '}
                  </li>
                  <li>
                    Digital Goods are offered to the Customer on an “as is”
                    basis;{' '}
                  </li>
                  <li>
                    Use of the Digital Good constitutes acceptance of these
                    Digital Goods Terms;{' '}
                  </li>
                  <li>
                    The Customer is responsible for all information the Customer
                    submits, transmits or otherwise make available during the
                    purchase or redemption of any Digital Good;{' '}
                  </li>
                  <li>
                    Lazada expressly excludes any guarantee, representation,
                    warranty, condition, term or undertaking of any kind,
                    express or implied, statutory or otherwise or any
                    representations or warranties arising from usage or custom
                    or trade or by operation of law, including (without
                    limitation) as to the sequence, originality, correctness,
                    completeness, accuracy, timeliness, currency,
                    noninfringement, merchantability or fitness for any
                    particular purpose in relation to any Digital Goods;{' '}
                  </li>
                  <li>
                    Lazada to the fullest extent permitted by law excludes all
                    warranties, rights and remedies (including warranties
                    implied by statute or otherwise) that the Customer would
                    otherwise be entitled by law.{' '}
                  </li>
                </ol>
              </li>
              <li>
                <b> Refunds, Exchange and Cancellations</b>
                <ol>
                  <li>
                    Any Digital Goods bought cannot be returned, refunded, or
                    cancelled on the Customer’s request after purchase.
                  </li>
                  <li>
                    Issued Digital Goods cannot be exchanged for cash. Each
                    Seller may have its own policy on exchange of issued Digital
                    Goods.{' '}
                  </li>
                  <li>
                    Replacement of the Digital Good may be allowed, at Lazada’s
                    discretion, if there is a systemic failure on Lazada’s part.{' '}
                  </li>
                  <li>
                    Exchange of a Product or Service purchased using a Digital
                    Good shall be subject to the Seller’s terms and conditions.{' '}
                  </li>
                  <li>
                    Lazada reserves the right to cancel or reject the use of any
                    Digital Goods at its sole discretion, including without
                    limitation, where it deems or suspects that any transaction
                    is fraudulent.
                  </li>
                </ol>
              </li>
              <li>
                <b> Availability of Digital Goods</b>
                <ol>
                  <li>
                    The Customer acknowledges that purchase or redemption of
                    Digital Goods are subject to resource availability,
                    including, without limitation, availability of lazada.sg
                    website and/or LAZADA mobile app.{' '}
                  </li>
                  <li>
                    Lazada does not make any warranty on the performance and
                    capability of the lazada.sg website or the LAZADA mobile
                    app.{' '}
                  </li>
                  <li>
                    The Customer accepts and acknowledges that Lazada may
                    suspend, terminate and/or discontinue the purchase or
                    redemption of the Digital Goods in its sole and absolute
                    discretion without prior notice and without any liability
                    whatsoever to the Customer.{' '}
                  </li>
                </ol>
              </li>
              <li>
                <b>Use and Disclosure of Data</b>
                <ol>
                  <li>
                    The Customer agrees that Lazada shall be entitled to use or
                    disclose any information or data disclosed by the Customer
                    in accordance with Lazada’s Privacy Policy. The Customer is
                    entitled to withdraw such consent in the procedure as
                    prescribed by Lazada from time to time.{' '}
                  </li>
                  <li>
                    Order fulfilment in relation to Digital Goods may be handled
                    by a partner, in which case Customers may receive direct
                    communication of Digital Good pin codes from the partner.
                  </li>
                </ol>
              </li>
              <li>
                <b>Miscellaneous</b>
                <ol>
                  <li>
                    The rights and protections conferred on Lazada under these
                    Digital Goods Terms shall be additional to the rights and
                    protections conferred on Lazada under the Terms of Use,
                    Privacy Policy as well as such other terms and conditions as
                    may be agreed to or accepted by the Customer.{' '}
                  </li>
                  <li>
                    Any clause in the Digital Goods Terms, Terms of Use, Privacy
                    Policy and other terms and conditions as may be agreed to or
                    accepted by the Customer that is invalid, unenforceable or
                    illegal shall be enforced as nearly as possible in
                    accordance with its terms, but shall otherwise be deemed
                    severed and shall not affect the enforceability of any other
                    clauses, which clauses shall continue to be valid and
                    enforceable to the fullest extent permitted by law.{' '}
                  </li>
                  <li>
                    Lazada reserves the right to alter, modify, add to or
                    otherwise vary these Digital Goods Terms from time to time,
                    and in such manner as Lazada deems appropriate. The Customer
                    shall be bound by the terms and conditions so amended. In
                    any event, if the Customer continues to use the Digital
                    Goods after such amendment, the Customer shall be deemed to
                    have accepted the amendments.{' '}
                  </li>
                </ol>
              </li>
            </ol>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.lazada.sg/helpcenter/digital-voucher-gift-cards-terms-and-condition-4075.html"
            >
              Learn More
            </a>
          </div>
        </div>
      </section>

      <div style={{ marginBottom: '30px', textAlign: 'center' }}>
        <Button type="primary" href="https://shop.sourcesage.co">
          Start to Shop Here
        </Button>
      </div>

      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  overflow-x: hidden;
  /* margin-top: 75px; */

  .ant-layout-header {
    background-color: #ffffff;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  .ant-layout-content {
    padding: 0px;
    background-color: #ffffff;
  }
  .ant-layout-footer {
    padding: 0px 50px;
    background-color: #ffffff;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
  }
  h2 {
    font-size: 20px;
  }
  div {
    font-size: 16px;
  }

  section {
    padding: 5% 0px;
  }
  .section__title {
    text-align: center;
    h1 {
      font-size: 2.2rem;
      margin: 0px;
      font-weight: bold;
    }
    p {
      font-size: 1.2rem;
    }
  }
  .section__content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;

    h2 {
      font-size: 1.7rem;
      line-height: 2rem;
      font-weight: bold;
      margin: 0px;
    }
  }

  .para-text {
    font-size: 1.2rem;
  }

  .collapse-box {
    .ant-collapse {
      margin-bottom: 10px;
      background: #d9d9d9;
    }
    .ant-collapse-header {
      font-size: 1.05rem !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    .ant-collapse-content-box {
      p {
        font-size: 0.95rem !important;
        line-height: 1.5rem !important;
      }
    }
  }
  .custom-divider {
    display: flex !important;
    align-items: center;
    margin: 30px 0px;
    height: 100%;
    .ant-divider-inner-text {
      display: inline-block;
      padding: 0 24px;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .store-block {
    background: #f5f5f57d;
    padding: 30px;
    border-radius: 10px;
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 20px;

    a {
      font-size: 13px;
    }

    ol {
      padding: 0px;
      padding-left: 10px;
      li {
        padding-bottom: 15px;
        font-size: 13px;
        text-align: justify;
        line-height: 1.6;
      }
    }

    ol {
      counter-reset: item;
    }
    li {
      display: block;
    }
    li:before {
      content: counters(item, '.') ' ';
      counter-increment: item;
    }
  }
`;
export default GiftTerms;
