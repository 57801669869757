import React from 'react';

import WebLinkDropdown from '../WebLinkDropdown';

const AllCategories = (props) => {
  const { link, categories } = props;

  return (
    <WebLinkDropdown
      link={{
        id: link.id,
        link: '/',
        children: [
          { id: 'all', name: 'All Products', count: 0 },
          ...(categories.items || []),
        ].map((cat) => {
          return {
            id: cat.id,
            name: `${cat.name} ${cat.count ? `(${cat.count})` : ''}`,
            link: `/products${cat.id === 'all' ? '' : `?category=${cat.id}`}`,
            prevIcon: cat.id === 'all' ? '' : 'search',
            afterIcon: '',
          };
        }),
        prevIcon: '',
        afterIcon: 'down',
        name: 'Products',
      }}
    />
  );
};

export default AllCategories;
