import * as UserAction from '../actions/UserAction';
import { ActionType, getType } from 'typesafe-actions';

const defaultState = {
  membership: null,
  company: null,
  full_name: null,
  id: null,
  phone_number: null,
  settings: null,
  username: null,
  token: null,
  role_id: '',
};
type UserActionType = ActionType<typeof UserAction>;

export default function user(
  state: CurrentUser = defaultState,
  action: UserActionType
): CurrentUser {
  switch (action.type) {
    case getType(UserAction.setCurrentUser):
      return { ...state, ...action.payload };
    case getType(UserAction.setCurrentUserCompany):
      return { ...state, company: action.payload };
    case getType(UserAction.setCurrentUserCompanyMember):
      return { ...state, company_member: action.payload };
    case getType(UserAction.setCurrentUserCompanyAccount):
      if (state.company) {
        return {
          ...state,
          company: { ...state.company, account_type: action.payload },
        };
      }
      break;
    case getType(UserAction.clearCurrentUser):
      return defaultState;
    case getType(UserAction.setBusinessCardInterest):
      return { ...state, business_card_interest: action.payload };
    default:
      return state;
  }
}
