import * as COLORS from '../constants/COLORS';
// @ts-ignore
import TopBarProgress from 'react-topbar-progress-indicator';

// @ts-ignore
TopBarProgress.config({
  barColors: {
    '0': COLORS.primaryDarkerColor,
    '1.0': COLORS.primaryLighterColor,
  },
  shadowBlur: 5,
});
export default TopBarProgress;
