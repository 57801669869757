import { createStandardAction } from 'typesafe-actions';

export const setCurrentUser = createStandardAction('USER_SET_CURRENT_USER')<
  CurrentUser
>();

export const setInvites = createStandardAction('USER_SET_INVITES')<
  CompanyMemberInvite[]
>();

export const setCurrentUserCompany = createStandardAction(
  'USER_SET_CURRENT_USER_COMPANY'
)<Company>();

export const setCurrentUserCompanyMember = createStandardAction(
  'USER_SET_CURRENT_USER_COMPANY_MEMBER'
)<CurrentUserCompanyMember>();

export const setCurrentUserCompanyAccount = createStandardAction(
  'USER_SET_CURRENT_USER_COMPANY_ACCOUNT'
)<number>();

export const clearCurrentUser = createStandardAction('USER_CLEAR_CURRENT_USER')<
  void
>();

export const setBusinessCardInterest = createStandardAction(
  'USER_SET_BUSINESS_INTEREST'
)<boolean>();
