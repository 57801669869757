import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
/*eslint-disable */
const Footer = () => {
  return (
    <FooterWrapper>
      <section>
        <Row gutter={[24, 8]}>
          <Col span={10}>
            <h3>About Company</h3>
            <p>
              SourceSage is Asia's largest B2B Marketplace Aggregator with the
              mission to make long tail buying hassle free for companies. With a
              global footprint across US, Singapore, Malaysia, Thailand,
              Vietnam, Indonesia, China, India, Australia and Japan, we have
              been entrusted by our clients such as DHL, Certis, Cushman &
              Wakefield, Mitsui, Anphat to become their Sage for Sourcing.
            </p>
            <br />
            <h4>Think Sourcing? Think SourceSage</h4>
            <p>
              Our unique differentiator lies in the ability for corporate
              purchasers to view over 100 million Ecommerce products and
              services across over 800 categories from multiple global and local
              B2B and B2C marketplaces in their own ERP or procurement
              environment (e.g. BeNeering, SAP Ariba, Oracle, Coupa, Workday
              etc) or one Whitelabeled by SourceSage.
            </p>
          </Col>
          <Col span={4}>
            <h3>Site Map</h3>

            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a
                  href="https://getsourcesage.mystrikingly.com/_blog"
                  target="_blank"
                >
                  Success stories
                </a>
              </li>
              <li>
                <a href="https://shop.sourcesage.co/login" target="_blank">
                  Create free account
                </a>
              </li>
            </ul>
          </Col>
          <Col span={6}>
            <h3>Contact</h3>
            <div>7500A Beach Road, The Plaza, 199591</div>
            <div>enquiry@sourcesage.co</div>
          </Col>
          <Col span={4}>
            <h3>Social Share</h3>
            <div className={'icon-group'}>
              <a
                href="https://www.facebook.com/sourcesage.co"
                target="_blank"
                style={{ width: '10px' }}
              >
                <img src="https://sme-assets.sourcesage.co/development/company/6f961054-2a12-48fa-82f4-b775a935b64b" />
              </a>
              {/* <a href="" target="_blank" style={{ width: '20px' }}>
                <img src="https://sme-assets.sourcesage.co/development/company/434d74b6-1607-4289-a584-1d540c399b4b" />
              </a> */}
              {/* <a href="" target="_blank" style={{ width: '20px' }}>
                <img src="https://sme-assets.sourcesage.co/development/company/4ee631d4-616d-48e2-98bb-24c16b97d4d7" />
              </a> */}
              <a
                href="https://www.linkedin.com/company/source-sage"
                target="_blank"
                style={{ width: '20px' }}
              >
                <img src="https://sme-assets.sourcesage.co/development/company/fbc3a090-5590-479d-9b6c-eeaa2ebbd23e" />
              </a>
            </div>
          </Col>
        </Row>
      </section>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  border-top: solid 1px #ddd;

  section {
    padding: 70px 20px !important;
    max-width: 1400px;
    margin: 0 auto;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  a {
    color: #000000;
  }
  ul {
    padding: 0px;
    list-style-type: none;
    li {
      line-height: 2.5rem;
    }
  }
  .icon-group {
    a {
      display: inline-block;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
  }
`;

export default Footer;
