import styled from 'styled-components';

export const LinkText = styled.div.attrs((props: any) => ({
  isMobile: props.isMobile || false,
}))`
  display: flex;
  align-items: center;

  p {
    margin: 0 5px 0;
    font-size: ${(props) => (props.isMobile ? '16px' : '14px')};
  }

  i.anticon {
    font-size: ${(props) => (props.isMobile ? '16px' : '14px')};
    margin: 0;
  }

  .ant-menu-sub .ant-menu-item:last-child p {
    font-weight: 400;
  }
`;

export const ButtonContainer = styled.div.attrs((props: any) => ({
  bgColor: props.bgColor || 'var(--ss-primary)',
  textColor: props.textColor || '#FFFFFF',
  isMobile: props.isMobile || false,
}))`
  padding: ${(props) => (props.isMobile ? '10px 0' : '0 10px')};

  .btn {
    width: fit-content;
    max-width: 100%;
    padding: 5px 15px;
    height: 38px;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.bgColor};
    border-radius: 5px;
    justify-content: center;
  }

  p {
    margin: 0 5px;
    color: ${(props) => props.textColor} !important;
    font-size: ${(props) => (props.isMobile ? '16px' : '14px')};
  }

  i.anticon {
    font-size: ${(props) => (props.isMobile ? '16px' : '14px')};
    margin: 0;
    color: ${(props) => props.textColor} !important;
  }
`;

export const SubMenuSpan = styled.span`
  font-size: 16px;
`;

export const ImageLink = styled.img`
  max-width: 100%;
  height: 40px;
  object-fit: contain;
`;
