import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LogoToHome = (props) => {
  return (
    <Link to="/" key="logo-to-home">
      <Logo src={props.companyLogo} alt="header logo" />
    </Link>
  );
};

const Logo = styled.img`
  max-height: 50px;
  object-fit: contain;
  object-position: left;
  @media (max-width: 767px) {
    max-width: 120px;
  }
`;

export default LogoToHome;
