import { defineCustomElements } from '@ss/ss-storefront-builder-demo/loader';
import React from 'react';
import * as ReactDOM from 'react-dom';
import UIApp from './UIApp';
import UICommonWrapper from './UICommonWrapper';
ReactDOM.render(
  <UICommonWrapper>
    <UIApp />
  </UICommonWrapper>,
  document.getElementById('root') as HTMLElement
);
defineCustomElements(window);
