import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withTracker } from './components/withTracker';
import GiftTerms from './pages/GiftTerms/index';
import {
  BlogPage,
  CategoryPage,
  Homepage,
  Page404,
  ProductPage,
  PropositionPage,
  PunchInPage,
  PunchOutPage,
} from './lazyload-routes';
class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/" component={withTracker(Homepage)} />
          <Route exact path="/product" component={withTracker(ProductPage)} />
          <Route
            exact
            path="/feature/punchout"
            component={withTracker(PunchOutPage)}
          />
          <Route
            exact
            path="/feature/punchin"
            component={withTracker(PunchInPage)}
          />
          <Route path="/blog" component={withTracker(BlogPage)} />
          <Route path="/category" component={withTracker(CategoryPage)} />
          <Route path="/proposition" component={withTracker(PropositionPage)} />
          <Route
            path="/gift-cards-terms-conditions"
            component={withTracker(GiftTerms)}
          />
          <Route component={withTracker(Page404)} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
