import React from 'react';
import styled from 'styled-components';
import AllCategories from './components/AllCategories';
import LogoToHome from './components/LogoToHome';
import WebLink from './WebLink';

type Props = {
  list: any[];
  companyLogo: string;
  className: string;
  categories: {
    items: Array<{
      id: number;
      name: string;
      count: number;
    }>;
  };
};

const WebLinkGroup = (props: Props) => {
  const { list, companyLogo, className, categories } = props;

  return (
    <LinksGroup className={className}>
      {list?.map((link) => {
        if (!!link?.special) {
          if (link?.special === 'logo-to-home') {
            return (
              <>
                <LogoToHome companyLogo={companyLogo} key={link.id} />
              </>
            );
          } else if (
            link?.special === 'dropdown-categories' &&
            categories?.items &&
            categories?.items?.length > 0
          ) {
            return (
              <AllCategories
                link={link}
                categories={categories}
                key={link?.id}
              />
            );
          } else {
            return null;
          }
        } else {
          return <WebLink link={link} key={link?.id} />;
        }
      })}
    </LinksGroup>
  );
};

const LinksGroup = styled.ul`
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style-type: none;

  a {
    margin: 0 7.5px;
    display: flex;
  }

  &.right {
    justify-content: flex-end;
  }
`;

export default WebLinkGroup;
