import { BrandingUtil } from '@ss/shared';
// Do not define color as name like green, red, blue...
// Let's define color as purpose of usage like textMedium, bgMedium...
const branding = BrandingUtil.getBranding();

export const primaryColor = branding.primary_color;
export const primaryDarkerColor = branding.primary_color;
export const primaryLighterColor = '#ffebeb';
export const contrastColor = '#f9f3c9';
export const infoColor = branding.primary_color;
export const white = '#fff';
export const red = '#f00';
export const textColor = 'rgba(0, 0, 0, 0.65)';
export const textGray = '#5B5A60';
export const bgGray = '#F2F3F7';
export const midGray = '#ececec';
export const moonGray = '#d2d2d2';
export const textMediumColor = '#bbbbbb';
export const borderColor = '#ddd';

export const textMediumBlack = '#000000';
export const bgOrange = '#FF7928';
export const verifiedColor = '#40D26E';
export const unverifiedColor = '#e41c40';

export const normalUser = '#038cfc';
export const premiumUser = '#9403fc';
export const VIPuser = '#fcba03';

export const paymentColor = '#e41c40';
export const receiptColor = '#40D26E';

// From ant design specs
export const successGreen = '#52c41a';
export const dangerRed = '#f5222d';

// Temporary
export const companyBrandColor = '#E4702B';
