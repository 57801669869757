import * as ConstantAction from '../actions/ConstantAction';
import * as CONSTANTS from '../constants/CONSTANTS';
import { ActionType, getType } from 'typesafe-actions';

const defaultState: Constants = {
  tradeTerms: CONSTANTS.TRADE_TERMS_DEFAULT,
  units_physical: CONSTANTS.UNITS_PHYSICAL_DEFAULT,
  units_temporal: CONSTANTS.UNITS_TEMPORAL_DEFAULT,
  currencies: CONSTANTS.CURRENCIES_DEFAULT,
  frequencies: CONSTANTS.FREQUENCIES_DEFAULT,
  paymentMethod: [],
  categories: [],
  deliveryMethod: [],
  industries: [],
  businessTypes: [],
  isFetched: false,
};
type ConstantActionType = ActionType<typeof ConstantAction>;

export default function constants(
  state: Constants = defaultState,
  action: ConstantActionType
): Constants {
  switch (action.type) {
    case getType(ConstantAction.saveCurrencies):
      return {
        ...state,
        currencies: action.payload,
      };
    case getType(ConstantAction.saveUnitsPhysical):
      return {
        ...state,
        units_physical: action.payload,
      };
    case getType(ConstantAction.saveUnitsTemporal):
      return {
        ...state,
        units_temporal: action.payload,
      };
    case getType(ConstantAction.saveTradeTerms):
      return {
        ...state,
        tradeTerms: action.payload,
      };
    case getType(ConstantAction.saveFrequencies):
      return {
        ...state,
        frequencies: action.payload,
      };
    case getType(ConstantAction.savePaymentMethod):
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case getType(ConstantAction.saveCategories):
      return {
        ...state,
        categories: action.payload,
      };
    case getType(ConstantAction.saveDeliveryMethods):
      return {
        ...state,
        deliveryMethod: action.payload,
      };
    case getType(ConstantAction.saveIndustries):
      return {
        ...state,
        industries: action.payload,
      };
    case getType(ConstantAction.saveBusinessTypes):
      return {
        ...state,
        businessTypes: action.payload,
      };
    case getType(ConstantAction.setIsFetch):
      return {
        ...state,
        isFetched: true,
      };
    default:
      return state;
  }
}
