export const TRADE_POSITION = {
  BUY: 'buy',
  SELL: 'sell',
};

export const TRADE_VISIBILITY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const TRADE_TRACSACTION_TYPE = {
  DIRECT: 'direct',
  EBIDDING: 'ebidding',
};

export const TRADE_PRICE_OPTION = {
  SINGLE: 'single',
  RANGE: 'range',
};

export const PRODUCT_TYPE = {
  SERVICES: 'SERVICES',
  GOODS_PHYSICAL: 'GOODS_PHYSICAL',
  GOODS_COMMODITY: 'GOODS_COMMODITY',
};

export const PRODUCT_TYPE_WITHNAME = {
  Services: 'SERVICES',
  Physical: 'GOODS_PHYSICAL',
  Commodity: 'GOODS_COMMODITY',
};

export const PRODUCT_TYPE_WITHOUTNAME = [
  'SERVICES',
  'GOODS_PHYSICAL',
  'GOODS_COMMODITY',
];

export const FREQUENCIES_DEFAULT: Unit[] = [
  {
    id: 5,
    name: 'monthly',
    unit_type: 'FREQUENCY',
  },
  {
    id: 6,
    name: 'yearly',
    unit_type: 'FREQUENCY',
  },
];
export const DELIVERY_METHOD = [
  'Door-to-Door',
  'Collect at Store',
  'FOB',
  'CIF',
  'CFR',
];

export const UNITS_PHYSICAL_DEFAULT: Unit[] = [
  {
    id: 3,
    name: 'piece(s)',
    unit_type: 'PHYSICAL',
  },
  {
    id: 4,
    name: 'box(es)',
    unit_type: 'PHYSICAL',
  },
];
export const UNITS_TEMPORAL_DEFAULT: Unit[] = [
  {
    id: 1,
    name: 'month(s)',
    unit_type: 'TEMPORAL',
  },
  {
    id: 2,
    name: 'year(s)',
    unit_type: 'TEMPORAL',
  },
];

export const TRADE_TERMS_DEFAULT: TradeTerm[] = [
  {
    id: 1,
    name: 'FOB',
  },
  {
    id: 2,
    name: 'CFR',
  },
];

export const CURRENCIES_DEFAULT = [
  'SGD',
  'USD',
  'CNY',
  'EUR',
  'GBP',
  'MYR',
  'THB',
  'INR',
];

export enum HTTPStatusCode {
  CONFLICT = 'CONFLICT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  BAD_REQUEST = 'BAD_REQUEST',
  UNPROCESSABLE = 'UNPROCESSABLE',
  TOO_MANY_REQUEST = 'TOO_MANY_REQUEST',
}

export const UPLOAD__ALLOWED_IMAGE_EXT = ['png', 'jpg', 'jpeg'];
export const UPLOAD__ALLOWED_DOCUMENT_EXT = [
  'pdf',
  'doc',
  'docx',
  'xlsx',
  'xls',
];

export const ATTACHMENT_TYPE = {
  IMAGE: 'image',
  DOCUMENT: 'document',
};

export enum TRADE_TYPES {
  QUOTATION = 'QUOTATION', // Buyers requesting a seller for Quote
  ENQUIRY = 'ENQUIRY', // Sellers searching for Buyers
}

export const LOGO = '/99sme-logo.png';

export const LOGO_LANDSCAPE = '/new-logo.png';

export const TERMS_AND_CONDITIONS_URL =
  'https://sme-assets.sourcesage.co/frontend/terms_and_conditions_of_registration_2020_11_03.pdf';
export const PRIVACY_POLICY_URL =
  'https://sme-assets.sourcesage.co/frontend/privacy_policy_2020_11_03.pdf';

export const RFQ_PLACEHOLDER_IMAGE =
  'https://sme-media.sourcesage.co/rfq-img-1%402x.jpg';

export const TRADE_PLACEHOLDER_IMAGE =
  'https://sme-media.sourcesage.co/no-image.jpg';

export const FRONTEND_ASSETS_URL = 'https://sme-assets.sourcesage.co/frontend/';

export const PAGINATION_DEFAULT = {
  pageSize: 20,
  total: 0,
  page: 1,
};
export const DEFAULT_TOTAL_ANNUAL_REVENUES = '[, 1]';
export const TOTAL_ANNUAL_REVENUES = {
  '[, 1]': 'Below US$1 Million',
  '[1, 10]': 'US$1 Million - US$10 Million',
  '[10, 50]': 'US$10 Million - US$50 Million',
  '[50, 100]': 'US$50 Million - US$100 Million',
  '[100,]': 'Above US$100 Million',
};

export const DEFAULT_TOTAL_EMPLOYEES = '[50, 100]';
export const TOTAL_EMPLOYEES = {
  '[, 50]': 'Fewer Than 50 People',
  '[50, 100]': '50 - 100 People',
  '[100, 501]': '100 - 501 People',
  '[501, 1000]': '501 - 1000 People',
  '[5000,]': 'More Than 5000 People',
};
export const DEFAULT_COUNTRY = 'SG';
export const FINANCING_OPTIONS = {
  INVOICE_FINANCING: 'Invoice Financing',
  WORKING_CAPITAL_LOAN: 'Working Capital Loan',
  PROJECT_FINANCING: 'Project Financing',
  RFQ_FINANCING: 'Rfq Financing',
};

export enum TradeRequestStatus {
  PendingConfirmation = 'PendingConfirmation',
  PendingPO = 'PendingPO',
  PendingPOConfirmation = 'PendingPOConfirmation',
  PendingInvoiceConfirmation = 'PendingInvoiceConfirmation',
  PendingDOConfirmation = 'PendingDOConfirmation',
  PendingDO = 'PendingDO',
  PendingDelivery = 'PendingDelivery',
  Delivered = 'Delivered',

  Shipped = 'Shipped',
  Completed = 'Completed',
  Expired = 'Expired',

  Confirmed = 'Confirmed',
  Rejected = 'Rejected',
  Undo = 'Undo',
}

export enum TradeRequestPaymentStatus {
  PENDING_ADVANCE_FROM_BUYER = 'PENDING_ADVANCE_FROM_BUYER',
  FAILED_ADVANCE_FROM_BUYER = 'FAILED_ADVANCE_FROM_BUYER',
  ADVANCE_PAID_BY_BUYER = 'ADVANCE_PAID_BY_BUYER',
  PAID_BY_BUYER = 'PAID_BY_BUYER',
  BANK_DEPOSIT_VERIFICATION_PENDING = 'BANK_DEPOSIT_VERIFICATION_PENDING',
  PENDING_REFUND_TO_BUYER = 'PENDING_REFUND_TO_BUYER',
  REFUNDED_TO_BUYER = 'REFUNDED_TO_BUYER',
  PENDING_BALANCE_FROM_BUYER = 'PENDING_BALANCE_FROM_BUYER',
  FAILED_BALANCE_PAYMENT_FROM_BUYER = 'FAILED_BALANCE_PAYMENT_FROM_BUYER',
  BALANCE_PAID_BY_BUYER = 'BALANCE_PAID_BY_BUYER',
  PENDING_PAYMENT_TO_SELLER = 'PENDING_PAYMENT_TO_SELLER',
  PAYMENT_REMITTED_TO_SELLER = 'PAYMENT_REMITTED_TO_SELLER',
  FAILED_REMITTANCE_TO_SELLER = 'FAILED_REMITTANCE_TO_SELLER',
  PENDING_REMITTANCE_CONFIRMATION = 'PENDING_REMITTANCE_CONFIRMATION',
  COMPLETED = 'COMPLETED',
}

export const REJECT_MODE = {
  Reject: 'reject',
  Counter: 'counter',
};

export const ADDITIONALS_FIELDS = [
  'TaxSchemeID',
  'SalesOrderID',
  'Payment Due Date',
];
export const LOOKING_TO = {
  Buy: 'Buy',
  Sell: 'Sell',
  BuyAndSell: 'Buy/Sell',
};

export const PAYMENT_OPTION = {
  Fast: 'Fast',
  PayNow: 'PayNow',
};
export enum TRADE_ROLE {
  BUYER = 'buyer',
  SELLER = 'seller',
}
export enum USER_ROLE {
  USER = 'user',
  STAFF = 'staff',
  ADMIN = 'admin',
}
export const REFUND_POLICIES: BasicConstant[] = [
  {
    id: 1,
    name:
      'This item is non-refundable, non-refundable and non-exchangeable upon item received by buyer',
  },
  {
    id: 2,
    name:
      'This item is exclusive of insurance guarantee, and buyer will have to purchase own insurance',
  },
  {
    id: 3,
    name:
      'For confirmed orders with partial payment terms, advance payments will be non-refundable if buyer chooses not to pay balance payment',
  },
];

export const DEVICE = {
  mobile: '(max-width: 576px)',
  tablet: '(max-width: 768px)',
  mobileWidth: '576px',
  tabletWidth: '768px',
};
export const MIN_PASSWORD_LENGTH = 8;

export enum COMPANY_MEMBER_STATUS {
  WAITING = 'WAITING',
  ACTIVE = 'ACTIVE',
}

export enum COMPANY_MEMBER_ROLE {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
}

export enum COMPANY_MEMBER_INVITE_STATUS {
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
}

export const INVITE_STATUS_COLOR = {
  [COMPANY_MEMBER_INVITE_STATUS.PENDING]: 'orange',
  [COMPANY_MEMBER_INVITE_STATUS.CANCELED]: 'gray',
  [COMPANY_MEMBER_INVITE_STATUS.REJECTED]: 'red',
};

export const COMPANY_MEMBER_STATUS_COLOR = {
  [COMPANY_MEMBER_STATUS.WAITING]: 'yellow',
};

export const WHATSAPP_TRADER_CONTACT = '6591508236';

export const DELIVERY_METHOD_LEAD_TIME_DEFAULT = 5;
export const DELIVERY_METHOD_LEAD_TIME = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
};
export enum DELIVERY_METHOD_RATE_TYPE {
  PER_DIMENSIONS = 'PER_DIMENSIONS',
  FIXED_RATE = 'FIXED_RATE',
  PER_PARCEL = 'PER_PARCEL',
}

export const PAYMENT_GATEWAYS = ['PayPal', 'Stripe', 'Braintree', 'Others'];

export const OTHER_SOLUTIONS = [
  {
    id: 1,
    name: 'Cheaper Online Payment Gateways',
  },
  {
    id: 2,
    name: 'Financing Solutions for my business',
  },
  {
    id: 3,
    name: 'Marketing Platforms or Solutions',
  },
  {
    id: 4,
    name: 'Workflow Automation Tools including invoicing, POs, DOs etc',
  },
  {
    id: 5,
    name: 'Logistics solutions to help deliver my goods',
  },
  {
    id: 6,
    name: 'Others',
  },
];

export const CUSTOMER_BASE = [
  'Singapore',
  'Malaysia',
  'Indonesia',
  'Vietnam',
  'Philippines',
  'Thailand',
  'China',
  'India',
  'North Asia (Japan or Korea)',
  'Africa',
  'Middle East',
  'Eastern Europe',
  'Western Europe',
  'North America',
  'South America',
  'Russia & Central Asia',
];

export const DEFAULT_TOTAL_ANNUAL_TRADE_VALUE = '[, 0.5]';
export const TOTAL_ANNUAL_TRADE_VALUE = {
  '[, 0.5]': 'Below 500K SGD',
  '[0.5, 2]': '500K - 2 Million SGD',
  '[2, 5]': '2 Million - 5 Million SGD',
  '[50, 10]': '5 Million - 10 Million SGD',
  '[10,]': 'Above 10 Million SGD',
};

export const FREQUENCY = ['Always', 'Often', 'Sometimes', 'Seldom', 'Never'];
export enum BUY_OPTION {
  direct,
  cart,
  sendEnquiry,
}
export const VIEW_ORDER_TYPE = {
  Sent: 'sent',
  Inbox: 'inbox',
};
export enum PaymentTab {
  methodSelection = '1',
  creditCard = 'CreditCard',
  payNow = 'PayNow',
  bankDeposit = 'BankDeposit',
  payOnCredit = 'PayOnCredit',
  promotion = 'Promotion',
}

export enum CompanyPageType {
  creating = 'creating',
  editing = 'editing',
  financing = 'financing',
}
export const StatusColor = {
  Pending: 'orange',
  PendingConfirmation: 'orange',
  PendingPOConfirmation: 'orange',
  PendingInvoiceConfirmation: 'orange',
  PendingDOConfirmation: 'orange',
  REQUESTED: 'orange',

  PendingPO: 'geekblue',
  PendingDO: 'geekblue',

  PendingDelivery: 'lime',

  Placed: 'blue',
  PickedUp: 'blue',
  Delivered: 'blue',

  Completed: 'green',
  PAID: 'green',
  REMITTED: 'green',
  Remitted: 'green',
  ACTIVE: 'green',
  ACCEPTED: 'green',

  Expired: 'red',
  Rejected: 'red',
  REJECTED: 'red',
  FAIL_PAYMENT_VERIFICATION: 'red',
  PaymentFailure: 'red',
  PROCESSING: 'orange',
  COMPLETED: 'green',
  PENDING_VERIFICATION: 'orange',
  PENDING_APPROVAL: 'orange',
  PENDING_PAYMENT: 'orange',
  PENDING_PAYMENT_VERIFICATION: 'orange',
  MARK_AS_RECEIVED: 'blue',
  MARK_AS_DELIVERY_RECEIVED: 'blue',
  PickupFailed: 'volcano',
};

export enum TradeOrderStatus {
  DRAFT = 'DRAFT',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  PAID = 'PAID',
  ORDER_ON_CREDIT = 'ORDER_ON_CREDIT',
  MARK_AS_DELIVERY_RECEIVED = 'MARK_AS_DELIVERY_RECEIVED',
}

export const PaymentStatusColor = {
  PENDING_ADVANCE_FROM_BUYER: 'orange',
  PENDING_REFUND_TO_BUYER: 'orange',
  PENDING_BALANCE_FROM_BUYER: 'orange',
  PENDING_PAYMENT_TO_SELLER: 'orange',
  PENDING_REMITTANCE_CONFIRMATION: 'orange',

  BALANCE_PAID_BY_BUYER: 'green',
  PAYMENT_REMITTED_TO_SELLER: 'green',
  ADVANCE_PAID_BY_BUYER: 'green',
  PAID: 'green',
  PAID_BY_BUYER: 'green',

  FAILED_BALANCE_PAYMENT_FROM_BUYER: 'red',
  FAILED_REMITTANCE_TO_SELLER: 'red',
  FAILED_ADVANCE_FROM_BUYER: 'red',

  REFUNDED_TO_BUYER: 'grey',
  COMPLETED: 'grey',
  BANK_DEPOSIT_VERIFICATION_PENDING: 'orange',
  REMITTED: 'gold',
};

export enum PayFor {
  PRODUCT = 0,
  PREMIUM_ACCOUNT = 1,
  EWALLET = 2,
}

// E-Wallet
export const PaymentType = {
  ONE_OFF: 'ONE_OFF',
  RECURRING: 'RECURRING',
};

export const RecurringTime = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  HALF_YEARLY: 'Half-Yearly',
  YEARLY: 'Yearly',
};

export const TransactionRequestStatus = {
  PAID: 'PAID',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PENDING_PAYMENT_VERIFICATION: 'PENDING_PAYMENT_VERIFICATION',
  FAIL_PAYMENT_VERIFICATION: 'FAIL_PAYMENT_VERIFICATION',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  HOLDING: 'HOLDING',
  REMITTED: 'REMITTED',
  ACTIVE: 'ACTIVE',
};

export const TransactionRequestPayeeStatus = {
  PAID: 'RECEIVED',
  PENDING_APPROVAL: 'PENDING_PAYMENT',
};
export const TransactionRequestPayerStatus = {
  REMITTED: 'PAID',
};

export const TransactionRequestCategory = {
  PAYROLL: 'Payroll',
  INSURANCE: 'Insurance',
  OFFICE_LEASES_RENTAL: 'Office Leases/Rental',
  TAXES: 'Taxes',
  MARKETING_ADVERTISING: 'Marketing/Advertising',
  LEGAL_PROFESSIONAL_FEES: 'Legal & Professional Fees',
  UTILITIES: 'Utilities',
  BANK_CHARGES: 'Bank Charges',
  GENERAL_EXPENSES: 'General Expenses',
  TELCO_BILLS: 'Telco Bills',
  SERVICE_CHARGES: 'Service Charges',
  TRANSPORT_CHARGES: 'Transport Charges',
  SUPPLIER_PAYMENTS: 'Supplier Payments',
  PURCHASE_OF_GOODS: 'Purchase of Goods',
  OTHERS: 'Others',
};
export const DEFAULT_CALLBACK_LOCALE = 'en';
export const languages: LocaleType[] = ['en', 'zh'];

export const MPGS_TRANSACTION_TYPE = 'PURCHASE';

export enum EWALLET_SUPPORT_CURRENCY {
  SGD = 'SGD',
  USD = 'USD',
}

export const PRODUCT_IMAGE_MAX_FILE = 9;
export const COMPANY_RATINGS = ['AAA', 'AA', 'A', 'B', 'BB', 'BBB'];
export const RATE_TO_SCORE = {
  AAA: 5,
  AA: 4,
  A: 3,
  B: 2,
  BB: 1,
  BBB: 0,
};

export const WEIGHT_UNITS = {
  g: 'Grams',
  kg: 'Kilograms',
  mg: 'Milligrams',
  lb: 'Pounds',
};

export const LENGTH_UNITS = {
  mm: 'Millimeters',
  cm: 'Centimeters',
  m: 'Meters',
  ft: 'Feet',
  in: 'Inches',
};

export const NO_IMAGE =
  'https://s3-ap-southeast-1.amazonaws.com/www.99smeb2b.com/assets/staging/public/image_placeholder.jpg';

export const selfCollectEmail = 'self-collect@self-collect.com';

export enum PURCHASE_REQUEST_STATUS {
  PLACED = 'PLACED',
  ORDERED = 'ORDERED',
  REJECTED = 'REJECTED',
}
