import antdEn from 'antd/es/locale/en_US';
import antdVi from 'antd/es/locale/vi_VN';
import antdZh from 'antd/es/locale/zh_CN';
import enTranslations from '../constants/translations/en-US.json';
import viTranslations from '../constants/translations/vi-VN.json';
import zhTranslations from '../constants/translations/zh-CN.json';
import { DEFAULT_CALLBACK_LOCALE } from '../constants/CONSTANTS';
import { includes, merge } from 'lodash';
import { languages } from '../constants/CONSTANTS';

const localeConfig = {
  en: {
    antd: antdEn,
    translations: enTranslations,
  },
  vi: {
    antd: antdVi,
    translations: merge({}, enTranslations, viTranslations),
  },
  zh: {
    antd: antdZh,
    translations: merge({}, enTranslations, zhTranslations),
  },
};

/**
 * Define custom formatters here.
 */
export const getFormats = () => ({
  number: {
    usd: { style: 'currency', currency: 'USD' },
  },
});

/**
 * Given browser's locale, returns only locales supported by the app. Defaults to 'en'.
 * Use first time only
 */
export function getAppLocale(browserLocale: string): string {
  const language = browserLocale.slice(0, 2);
  if (language && includes(languages, language)) {
    return language;
  }
  return DEFAULT_CALLBACK_LOCALE;
}

export function getLocaleMessage(locale: string) {
  const localMsg = flattenMessages(localeConfig[locale].translations);
  return { locale, localMsg, antdMsg: localeConfig[locale].antd };
}

function flattenMessages(nestedMessages: object, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: object, key: string) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}
