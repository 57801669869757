import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

const TawkWidget = () => {
  const user = useSelector((state: any) => state?.user);
  const tawkMessengerRef: any = useRef();

  const onLoad = () => {
    const name = `${user?.full_name ? user.full_name : '--'}`;
    const email = `${user?.username ? user.username : '--'}`;
    const phone = `${user?.phone_number ? user.phone_number : '--'}`;
    if (tawkMessengerRef?.current) {
      // console.log('onLoad', user);
      tawkMessengerRef?.current?.setAttributes(
        {
          Email: email,
          Name: name,
          Phone: phone,
        },
        (err) => {}
      );
    }
  };

  return (
    <TawkMessengerReact
      propertyId={'63184bd737898912e967b4b9'}
      widgetId={'1gda40lg1'}
      onLoad={onLoad}
      ref={tawkMessengerRef}
    />
  );
};

export default TawkWidget;
