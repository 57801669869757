import * as Sentry from '@sentry/browser';
import CompanyProvider from 'components/CompanyProvider';
import TawkWidget from 'components/TawkWidget';
import LogRocket from 'logrocket';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'typeface-roboto';
import UIGlobalStyles from 'UIGlobalStyles';
import 'url-search-params-polyfill';
import { QueryParamProvider } from 'use-query-params';
import AppIdleTimer from './components/AppIdleTimer';
import CacheBuster from './components/CacheBuster';
import ConnectedLocale from './components/ConnectedLocale';
import ErrorBoundary from './components/ErrorBoundary';
import store from './store';

const UICommonWrapper = (props) => (
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <ConnectedLocale>
        <ErrorBoundary>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <React.StrictMode>
                <CompanyProvider>
                  <UIGlobalStyles>
                    <>
                      <AppIdleTimer />
                      <CacheBuster>{props.children}</CacheBuster>
                      <TawkWidget />
                    </>
                  </UIGlobalStyles>
                </CompanyProvider>
              </React.StrictMode>
            </QueryParamProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ConnectedLocale>
    </PersistGate>
  </Provider>
);

const date = new Date().getDate();
if (date <= 15 && process.env.REACT_APP_LOG_ROCKET_ID_1) {
  LogRocketInitWithoutAuth(String(process.env.REACT_APP_LOG_ROCKET_ID_1));
} else if (date > 15 && process.env.REACT_APP_LOG_ROCKET_ID_2) {
  LogRocketInitWithoutAuth(String(process.env.REACT_APP_LOG_ROCKET_ID_2));
}

function LogRocketInitWithoutAuth(id: string) {
  LogRocket.init(id, {
    network: {
      requestSanitizer: (request: LogRocketRequest) => {
        // if the url contains 'auth'
        if (request.url.toLowerCase().indexOf('auth') !== -1) {
          // ignore the request response pair
          return null;
        }
        // otherwise log the request normally
        return request;
      },
    },
  });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

if (window.Cypress) {
  window.store = store.store;
}

export default UICommonWrapper;
