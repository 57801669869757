import { ActionType, createStandardAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { fetchAPI } from '../utils/api';

export const saveCurrencies = createStandardAction('CONSTANT_SAVE_CURRENCIES')<
  string[]
>();

export const saveUnitsPhysical = createStandardAction(
  'CONSTANT_SAVE_UNITS_PHYSICAL'
)<Unit[]>();

export const saveUnitsTemporal = createStandardAction(
  'CONSTANT_SAVE_UNITS_TEMPORAL'
)<Unit[]>();

export const saveTradeTerms = createStandardAction('CONSTANT_SAVE_TRADE_TERMS')<
  TradeTerm[]
>();

export const savePaymentMethod = createStandardAction(
  'CONSTANT_SAVE_PAYMENT_METHOD'
)<PaymentMethod[]>();

export const saveFrequencies = createStandardAction(
  'CONSTANT_SAVE_FREQUENCIES'
)<Unit[]>();

export const saveCategories = createStandardAction('CONSTANT_SAVE_CATEGORIES')<
  Category[]
>();

export const saveDeliveryMethods = createStandardAction(
  'CONSTANT_SAVE_DELIVERY_METHOD'
)<DeliveryMethod[]>();

export const saveBusinessTypes = createStandardAction(
  'CONSTANT_SAVE_BUSINESS_TYPES'
)<BusinessType[]>();

export const saveIndustries = createStandardAction('CONSTANT_SAVE_INDUSTRIES')<
  BusinessType[]
>();

export const setIsFetch = createStandardAction('CONSTANT_SET_IS_FETCH')<void>();

const fetchTradeConstants = () =>
  /**
   * TODO: FETCH trades/constants if needed, if not exists.
   */
  async (dispatch: Dispatch<any>) => {
    const constants = await fetchAPI('trade/globals/all', {
      method: 'GET',
    });
    if (constants.trade_terms) {
      dispatch(saveTradeTerms(constants.trade_terms));
    }
    if (constants.currencies) {
      dispatch(saveCurrencies(constants.currencies));
    }
    if (constants.quanity_units__physical) {
      dispatch(saveUnitsPhysical(constants.quanity_units__physical));
    }
    if (constants.quanity_units__frequency) {
      dispatch(saveFrequencies(constants.quanity_units__frequency));
    }
    if (constants.quanity_units__temporal) {
      dispatch(saveUnitsTemporal(constants.quanity_units__temporal));
    }
    if (constants.payment_terms) {
      dispatch(savePaymentMethod(constants.payment_terms));
    }
    if (constants.product_categories) {
      dispatch(saveCategories(constants.product_categories));
    }
    if (constants.delivery_methods) {
      dispatch(saveDeliveryMethods(constants.delivery_methods));
    }
    dispatch(setIsFetch());
  };

export const fetchTradeConstantsIfNeeded = () => (
  dispatch: any,
  getState: () => RootState
) => {
  const {
    constants: { isFetched },
  } = getState();
  if (!isFetched) {
    dispatch(fetchTradeConstants());
  }
};
export const fetchCompanyConstantIfNeeded = () => async (
  dispatch: Dispatch<
    ActionType<typeof saveIndustries> | ActionType<typeof saveBusinessTypes>
  >,
  getState: () => RootState
) => {
  const {
    constants: { industries = [], businessTypes = [] },
  } = getState();
  if (!industries.length) {
    const { items } = await fetchAPI('company/industries');
    dispatch(saveIndustries(items));
  }
  if (!businessTypes.length) {
    const { items } = await fetchAPI('company/business_types');
    dispatch(saveBusinessTypes(items));
  }
};
