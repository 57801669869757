type ConfigReducerType = {
  logo: string;
  menuLeft: any[];
  menuRight: any[];
  template_id: number;
};

const defaultState: ConfigReducerType = {
  logo:
    'https://sme-assets.sourcesage.co/production/company/577f4a88-3479-469c-9c13-14a3487e06b4',
  menuLeft: [],
  menuRight: [],
  template_id: 1,
};

export default function ShopReducer(
  state: ConfigReducerType = defaultState,
  action
) {
  switch (action?.type) {
    case 'SET_CONFIG':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
