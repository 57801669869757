import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import LinkContent from './LinkContent';
import WebLinkDropdown from './WebLinkDropdown';

const LinkContainer = (props) => {
  const { url, children } = props;
  const sameSite = url.startsWith(window.location.origin);
  const clientRedirect = url.startsWith('/');

  if (sameSite) {
    const href = '/' + url.split('/').slice(3).join('/');

    return (
      <NavLink to={href.toLowerCase()} exact activeClassName="active">
        {children}
      </NavLink>
    );
  } else if (clientRedirect) {
    return (
      <NavLink to={url.toLowerCase()} exact activeClassName="active">
        {children}
      </NavLink>
    );
  } else {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
};

type Props = {
  link: any;
};

const WebLink = (props: Props) => {
  const { link } = props;

  if (link.type === 'dropdown') {
    return <WebLinkDropdown link={link} />;
  } else {
    return (
      <LinkWrapper>
        <LinkContainer url={link.link}>
          <LinkContent link={link} />
        </LinkContainer>
      </LinkWrapper>
    );
  }
};

const LinkWrapper = styled.li`
  margin: 0 7.5px;

  a {
    color: #555555;

    i,
    p {
      transition: all 0.15s ease-out;
    }

    &:hover {
      color: var(--ss-primary);

      p,
      i {
        color: var(--ss-primary);
      }
    }

    p {
      text-align: center;
      margin: 0;
    }

    i:nth-child(1) {
      margin: 0 5px 0 0;
    }

    i:not(:nth-child(1)) {
      margin: 0 0 0 5px;
    }
  }
`;

export default WebLink;
