import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Helmet from 'react-helmet';
import { useEffect } from 'react';

const UIGlobalStyles = ({ children }) => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    rootElement.style.height = '100%';
    rootElement.style.display = 'block';
  }, []);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <title>Sourcesage</title>
        <meta name="description" content="description of the App" />
        <meta name="theme-color" content="#38316F" />
      </Helmet>
      <GlobalStyle />
      {children}
    </>
  );
};

export default UIGlobalStyles;

const GlobalStyle = createGlobalStyle`
:root{
  --ss-primary: #38316F;
  --ss-primary-light : #50469e;
  --ss-secondary: #38316F;
  --ss-dark:#000000;
  --ss-border-color: #CAD0E0;
  --ss-font-header: 'DM Sans', sans-serif;
  --ss-font-text: 'DM Sans', sans-serif;
}
body {
  font-family: var(--ss-font-text);
}
*{
  font-family: var(--ss-font-text);
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--ss-font-header);
  font-weight:600;
  color: var(--ss-dark);
}



.mt-1{
    margin-top: 1rem;
  }
  .mt-2{
    margin-top: 2rem;
  }
  .mt-3{
    margin-top: 3rem;
  }
  .mt-4{
    margin-top: 4rem;
  }
  .mt-5{
    margin-top: 5rem;
  }

  .mb-1{
    margin-bottom: 1rem;
  }
  .mb-2{
    margin-bottom: 2rem;
  }
  .mb-3{
    margin-bottom: 3rem;
  }
  .mb-4{
    margin-bottom: 4rem;
  }
  .mb-5{
    margin-bottom: 5rem;
  }

  .my-1{
    margin: 1rem 0;
  }
  .my-2{
    margin: 2rem 0;
  }
  .my-3{
    margin: 3rem 0;
  }
  .my-4{
    margin: 4rem 0; 
  }
  .my-5{
    margin: 5rem 0;
  }


::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 10px;
  border: 3px solid #fff;
}
.ant-input:hover, .ant-select-selection:hover, .ant-select-open .ant-select-selection{
  border-color: #555555  !important;
  box-shadow: none !important;
}
.ant-input:focus, .ant-select-selection:focus{
  border-color: #555555 !important;
  box-shadow: none !important;
}
.ant-btn.ant-btn-primary.christmas:before {
display: none!important;
}
.ant-tag {
  cursor: default;
}
.rc-virtual-list-holder {
  overflow-x: hidden !important;
  padding: 10px 20px !important;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #dddddd, #ffffff, #dddddd, #dddddd);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.ant-btn-primary {
  background-color: var(--ss-primary);
  border-color: var(--ss-primary);
  
  &:hover, &:focus {
    background-color: var(--ss-primary);
    border-color: var(--ss-primary);
  }
}

.ant-btn-link {
  color: var(--ss-primary);

  &:active, &:focus, &:hover {
    color: var(--ss-primary);
  }
}

a, a:hover {
  color: var(--ss-primary);
}

.ant-form-item-control {
    line-height: 34px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--ss-primary);
  border-color: var(--ss-primary);
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--ss-primary);
}

.ant-checkbox-checked::after {
  border-color: var(--ss-primary);
}

/** Sidebar **/
.ant-layout-sider {
    &.ant-layout-sider-dark{
      background: var(--ss-dark)!important;
    }
  }
  .ant-menu-dark{
    background:  var(--ss-dark)!important;
    .ant-menu-item-selected{
      background: var(--ss-primary) !important;
    }
  }

  .ant-layout-sider-light{
    background: #e5e5f3 !important;

    .ant-layout-sider-children{
     // padding:20px;
    }

    .ant-menu{
      background: transparent !important;
    }
    .ant-menu-item{
      height: 50px !important;
      line-height: 50px !important;
      border-radius:10px;
    }
    .ant-menu-item > a {
      color: #ffffff
    }
    .ant-menu-item > a:hover {
      color: #ffffff
    }
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
    }
  }

  .ant-menu-light{
    .ant-menu-item-selected{
      background: var(--ss-primary) !important;
      color: #ffffff !important;
      a{
        color: #ffffff !important;
      }
     
    }
  }
  
/** Sidebar End **/

/** Button **/
a{
    color: var(--ss-primary);
    &:hover{
      color: var(--ss-primary);
    }
  }

  .ant-btn-primary{
    background: var(--ss-primary);
    border-color:var(--ss-primary);

    &:hover{
      color: white !important;
      background: var(--ss-primary-light) !important;
      border-color:var(--ss-primary-light) !important;
    }
    &:active{
      color: white  !important;
      background: var(--ss-primary-light) !important;
      border-color:var(--ss-primary-light) !important;
    }
    &:focus{
      color: white  !important;
      background: var(--ss-primary-light)!important;
      border-color:var(--ss-primary-light)!important;
    }
  }

  .ant-btn-link{
    color: var(--ss-primary);
    background-color: transparent;
    border-color: transparent !important;
  }

  /** Button End **/


  /** Table **/
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #f5f5f5 !important;
  }
  table {
    .ant-table-column-title{
      font-size:12px !important;
    }
  }
  /** Table End **/

  /** Pagination **/
  .ant-pagination-item{
    a{
      &:hover{
    color: var(--ss-primary)  !important;
      }
    }
  }
  .ant-pagination-item-active {

      border-color: var(--ss-primary)  !important;
  }
  .ant-pagination-item-link{
    &:hover{
      border-color: var(--ss-primary)  !important;
    }
  }
  /** Pagination End **/

  /** Tabs **/
  .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--ss-primary) !important;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: var(--ss-primary)!important;
    }
    .ant-tabs-ink-bar{
      background-color:var(--ss-primary) !important;
    }
  /** Tabs End **/

  /**  Dropdown **/
  .ant-dropdown-menu-item-selected{
 >a{
  color: var(--ss-primary) !important;
  background-color: #f5f5f5 !important;
 }
}
.ant-select-dropdown-menu-item-selected{
  background-color: #f5f5f5 !important;
}
.ant-select-dropdown-menu-item{
  &:hover{
    background-color: #f5f5f5 !important;
  }
}
.ant-dropdown-menu-item{
  &:hover{
    background-color: #f5f5f5 !important;
  }
}
 /**  Dropdown End **/

/** Checkbox & Radio Button **/
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--ss-primary);
  border-color:var(--ss-primary);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color:var(--ss-primary) !important;
  border-color: var(--ss-primary)  !important;
  -webkit-box-shadow: -1px 0 0 0 var(--ss-primary)  !important;
  box-shadow: -1px 0 0 0 var(--ss-primary)  !important;
  &:hover{
  color:var(--ss-primary) !important;
  border-color: var(--ss-primary)  !important;
  -webkit-box-shadow: -1px 0 0 0 var(--ss-primary)  !important;
  box-shadow: -1px 0 0 0 var(--ss-primary)  !important;
  }
}

.ant-tree li .ant-tree-node-content-wrapper{
  &:hover{
      background-color: #f5f5f5 !important;
    }
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #f5f5f5 !important;
    &:hover{
      background-color: #f5f5f5 !important;
    }
*::selection {
  background-color: var(--ss-primary);
  color: #fff;
}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.ant-layout-sider-dark{
  .ant-menu{
  .ant-menu-item{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    a{
      color: rgba(255, 255, 255, 0.85);
    }
    .anticon{
      font-size: 15px;
    }
  }
}
}


.ant-layout{
background: #dce1e8;
}
.ant-layout-content{
  padding: 15px;
  padding-top: 35px;
}

.page-top-bar{
  display: flex;
  min-height: 55px;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #eceff0;
  margin-bottom: 15px;
  padding-bottom:5px;
  padding-left: 25px;
  padding-right:25px;
  overflow: hidden;
  margin-left: -25px;
}
.page-container{
  overflow: hidden;
  overflow-y: auto;
  padding-right: 25px;
  height: 100%;
}

.info-item {
    margin-bottom: 20px;
    label {
      font-size: 15px;
      color: #474e60;
      letter-spacing: 0.08px;
      line-height: 21px;
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
    }
    span {
      font-size: 14px;
      color: #7b85a0;
      letter-spacing: 0.08px;
      line-height: 23px;
      display: block;

      img {
        display: block;
        max-width: 80%;
        margin-bottom: 15px;
      }
    }
  }

  .subtitle{
    font-weight: bold;
    font-size: 18px;
    color: #474E60;
    letter-spacing: 0.1px;
    line-height: 25px;
    i{
      margin-right: 5px;
    }
    margin-bottom: 30px;
  }

  .unstyled{
    padding:0px;
    margin: 0px;
    list-style-type: none;
  }

  .grey-input-style{
    .ant-form-item-explain {
    line-height: 25px;
  }
  .ant-form-item-has-error {
    .ant-form-item-explain {
      color: red;
    }
  }
  .ant-form-item {
    margin-bottom: 15px !important;
  }
  .ant-form-item-label {
    line-height: 35px;
  }
  input.ant-input {
    height: 45px;
    border-radius: 5px;
    background: #f3f6fa;
    border: none;
  }
  textarea.ant-input {
    border-radius: 5px;
    background: #f3f6fa;
    border: none;
  }
  .ant-select-selection--single {
    position: relative;
    height: 45px;
    cursor: pointer;
    border-radius: 5px;
    background: #f3f6fa;
    border: none;
  }
  .ant-select-selection__rendered {
    line-height: 45px;
  }
  .helper-text {
    margin-top: 5px;
    font-size: 13px;
    font-style: italic;
    color: #7b85a0;
    letter-spacing: 0.07px;
    line-height: 20px;
    display: block;
  }
  button {
    height: 45px;
  }

 

}`;
