import * as GoogleAnalytics from 'react-ga';
import React from 'react';
import { getMatchId } from '../utils/route';
import { RouteComponentProps } from 'react-router';

export const initializeGA = (userId: number | null = null) => {
  const TRACKING_CODE = `${process.env.REACT_APP_GA_TRACKING_CODE}`;
  let gaOptions: GoogleAnalytics.GaOptions | undefined;
  if (userId) {
    gaOptions = {
      clientId: String(userId),
    };
  } else if (window.hasOwnProperty('localStorage')) {
    const item = JSON.parse(
      window.localStorage.getItem('persist:root') || '{}'
    );
    if (item && item.user != null && item.user !== 'null') {
      const user = JSON.parse(item.user);
      if (user.id) {
        gaOptions = {
          clientId: String(user.id),
        };
      }
    }
  }
  GoogleAnalytics.initialize(TRACKING_CODE, { gaOptions });
};

initializeGA();

export const withTracker = <WithTrackerProps extends object>(
  Component: React.ComponentType<WithTrackerProps>
) => {
  const track = (page: any, productId?: number) => {
    if (page.includes('/search?q=')) {
      const searchText = page.split('?q=')[1];
      GoogleAnalytics.event({
        category: 'SearchProduct',
        action: searchText,
      });
    } else {
      GoogleAnalytics.set({
        page,
      });
      GoogleAnalytics.pageview(page);
    }
  };

  type Props = WithTrackerProps & RouteComponentProps<{ tradeName: string }>;

  return class WithTracker extends React.Component<Props> {
    componentDidMount() {
      const page = window.location.href;
      const { tradeName } = this.props.match.params;
      if (tradeName) {
        const tradeId = getMatchId(tradeName);
        track(page, parseInt(tradeId, 10));
        return;
      }
      track(page);
    }

    componentDidUpdate(prevProps: any) {
      const page = window.location.href;
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;
      const { tradeName } = this.props.match.params;
      if (currentPage !== nextPage) {
        if (tradeName) {
          const tradeId = getMatchId(tradeName);
          track(nextPage, parseInt(tradeId, 10));
          return;
        }
        track(page);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};
