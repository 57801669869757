import React from 'react';
import versionJson from './version.json';

type Props = {
  children: any;
};
type State = {
  loading: boolean;
  isLatestVersion: boolean;
};

const refreshCacheAndReload = () => {
  if ('caches' in window) {
    // Service worker cache should be cleared with caches.delete()
    const clearCaches = [];
    caches.keys().then((names) => {
      for (const name of names) {
        clearCaches.push(caches.delete(name));
      }
    });
    Promise.all(clearCaches).then(() =>
      // delete browser cache and hard reload
      window.location.reload(true)
    );
  }
};
class CacheBuster extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
    };
  }

  componentDidMount() {
    fetch('/meta.json', {
      cache: 'no-store',
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = versionJson.version;

        const shouldForceRefresh = latestVersion !== currentVersion;
        if (shouldForceRefresh) {
          // tslint:disable-next-line: no-console
          console.log(
            `We have a new version - ${latestVersion} vs ${currentVersion}. Should force refresh`
          );
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          // tslint:disable-next-line: no-console
          console.log(
            `You already have the latest version - ${latestVersion} vs ${currentVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion } = this.state;
    if (loading) {
      return null;
    }
    if (!loading && !isLatestVersion) {
      refreshCacheAndReload();
    }

    return this.props.children;
  }
}

export default CacheBuster;
