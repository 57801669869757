import { Modal } from 'antd';
import * as Raven from 'raven-js';
import React from 'react';

type State = {
  error: boolean;
};
type Props = {};
class ErrorBoundary extends React.Component<Props, State> {
  state = { error: false };

  componentDidCatch(error: any, info: any) {
    this.setState({ error: true });
    Raven.captureException(error, { extra: info });
  }

  render() {
    if (this.state.error) {
      return Modal.error({
        title: "We're sorry — something's gone wrong",
        content: 'Our team has been notified, but click here to go back',
        onOk: () => window.location.replace('/'),
      });
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
