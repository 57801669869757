import React from 'react';
import IdleTimer from 'react-idle-timer';
import { fetchAPI, getUserFromLocalStorage } from '../utils/api';
import { Modal } from 'antd';

type State = {
  showModal: boolean;
};
const secondToExpired = 90; // in seconds
const timeout = 1000 * 60 * 60 * 1 - 1000 * secondToExpired; // 1 hour - 90 seconds
export default class AppIdleTimer extends React.Component<{}, State> {
  idleTimer: IdleTimer | null;
  constructor(props: {}) {
    super(props);
    this.idleTimer = null;
    this.state = {
      showModal: false,
    };
  }
  render() {
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        element={document}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={timeout} // 1 hour
      />
    );
  }

  onAction = async () => {
    const user = getUserFromLocalStorage();
    if (this.idleTimer && user && user.verified && user.id) {
      // Refresh every 10 minutes
      if (this.idleTimer.getElapsedTime() > timeout) {
        try {
          await fetchAPI('auth/refresh', { method: 'POST' });
          this.idleTimer.reset();
        } catch (e) {}
      }
    }
  };

  handleOk = async () => {
    const user = getUserFromLocalStorage();
    if (this.idleTimer && user && user.verified && user.id) {
      try {
        await fetchAPI('auth/refresh', { method: 'POST' });
        this.idleTimer.reset();
      } catch (e) {}
    }
    this.setState({ showModal: false });
  };

  onIdle = () => {
    const user = getUserFromLocalStorage();
    if (
      this.idleTimer &&
      !this.state.showModal &&
      user &&
      user.verified &&
      user.id
    ) {
      Modal.confirm({
        title: 'Session about to expire',
        content:
          'You will be logged out in 90 seconds due to inactivity, would you like to extend the session?',
        onOk: () => this.handleOk(),
        onCancel: () => {
          window.localStorage.clear();
          setTimeout(() => {
            window.localStorage.clear();
          });
          window.location.href = '/login';
        },
        okText: 'Stay',
        okType: 'primary',
        cancelText: 'Logout',
      });

      this.idleTimer.pause();
      this.setState({ showModal: true });
    }
  };
}
