import * as Cookies from 'js-cookie';
import * as parser from 'query-string';
import { HTTPStatusCode } from '../constants/CONSTANTS';
import { identity, includes, isEmpty, pickBy } from 'lodash';
import { message, Modal } from 'antd';
import { translation } from '../store';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const downloadableContentTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'application/zip',
];

interface IFetchAPIOptions extends RequestInit {
  auth?: boolean; // if true, auth token is put into Headers, else it's put into query string.
  payload?: {};
  token?: string;
  version?: string;
  is_json?: boolean;
  force_full_endpoint?: boolean;
  refreshed?: boolean;
}

class HTTPError extends Error {
  code?: string;
  errorBody?: string;
  constructor(errorMessage: string, errorCode: string, errorBody?: string) {
    super(errorMessage);
    this.code = errorCode;
    this.errorBody = errorBody;
  }
}

export const getToken = (): string | string[] | undefined => {
  return Cookies.get('access_token_cookie');
};

export const getUserFromLocalStorage = (): User | undefined => {
  if (window.hasOwnProperty('localStorage')) {
    const item = JSON.parse(
      window.localStorage.getItem('persist:root') || '{}'
    );
    if (item && item.user != null && item.user !== 'null') {
      const user = JSON.parse(item.user);
      if (user.id) {
        return user;
      }
    }
  }
  return undefined;
};

export const refreshAndGetToken = async () => {
  if (window.hasOwnProperty('localStorage')) {
    const item = JSON.parse(
      window.localStorage.getItem('persist:root') || '{}'
    );
    if (item && item.user != null && item.user !== 'null') {
      const user = JSON.parse(item.user);
      if (user.refresh) {
        const refreshResponse = await window.fetch(
          `${process.env.REACT_APP_API_URL}/api/v1.0/auth/refresh`,
          {
            headers: new Headers({
              Authorization: `Bearer ${user.refresh}`,
            }),
            method: 'POST',
          }
        );
        if (refreshResponse.ok) {
          const refreshData = await refreshResponse.json();
          const newUser = { ...user, token: refreshData.token };
          const newItem = JSON.parse(
            window.localStorage.getItem('persist:root') || '{}'
          );
          newItem.user = JSON.stringify(newUser);
          localStorage.setItem('persist:root', JSON.stringify(newItem));
        }
      }
    }
  }
};

export const getLang = (): string | string[] | undefined => {
  const lang = parser.parse(window.location.search).lang;
  return lang || 'en';
};

export const fetchAPI = async (
  endpoint: string,
  options?: IFetchAPIOptions
): Promise<any> => {
  const {
    payload = {},
    version = '1.0',
    method = 'GET',
    is_json = true,
    force_full_endpoint = false,
    ...fetchOptions
  }: IFetchAPIOptions = options || {};

  if (
    endpoint === 'auth/refresh' &&
    window.localStorage.getItem('authPopup') === '1'
  ) {
    return;
  }
  let body;

  let headers: any = {};
  let qs = {};
  const lang = getLang();

  if (lang) {
    headers = { ...headers, 'Accept-Language': lang };
  }

  if (is_json) {
    headers = { ...headers, ...defaultHeaders };
  }
  if (endpoint === 'marketplace/auth/refresh') {
    const CRSFRefreshToken = Cookies.get('csrf_refresh_token');
    const refreshToken = Cookies.get('ss_refresh_token');

    if (CRSFRefreshToken) {
      headers['X-CSRF-TOKEN'] = CRSFRefreshToken;
    }
    if (refreshToken) {
      headers.Authorization = `Bearer ${refreshToken}`;
    }
  } else {
    const CRSFAccessToken = Cookies.get('csrf_access_token');
    const accessToken = Cookies.get('ss_token');

    if (CRSFAccessToken) {
      headers['X-CSRF-TOKEN'] = CRSFAccessToken;
    }
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    headers = { ...headers, ...fetchOptions.headers };
  }
  if (method === 'GET') {
    qs = parser.stringify(pickBy({ ...qs, ...payload }, identity));
  } else {
    if (fetchOptions.body) {
      body = fetchOptions.body;
    } else {
      // for safari, you can't pass empty {} to body ...
      body = Object.keys(payload).length ? JSON.stringify(payload) : undefined;
    }
  }

  let url = `${process.env.REACT_APP_API_URL}/api/v${version}/${endpoint}${
    !isEmpty(qs) ? `?${qs}` : ''
  }`;

  if (force_full_endpoint) {
    url = endpoint;
  }

  const response = await window.fetch(url, {
    body,
    headers: new Headers(headers),
    method,
    credentials: 'include',
  });
  if (!response.ok) {
    const json = await response.json();

    if (json.error_code === HTTPStatusCode.SERVER_ERROR) {
      message.error(translation().response_code.SERVER_ERROR);
    } else if (json.error_code === HTTPStatusCode.UNPROCESSABLE) {
      message.error(translation().response_code.UNPROCESSABLE);
    } else if (json.error_code === HTTPStatusCode.TOO_MANY_REQUEST) {
      message.error('Too many attempts. Please wait and try again later.');
    } else if (json.error_code === HTTPStatusCode.UNAUTHORIZED) {
      if (!options?.refreshed) {
        const CRSFRefreshToken = Cookies.get('csrf_refresh_token');
        const refreshToken = Cookies.get('ss_refresh_token');

        if (CRSFRefreshToken) {
          headers['X-CSRF-TOKEN'] = CRSFRefreshToken;
        }
        if (refreshToken) {
          headers.Authorization = `Bearer ${refreshToken}`;
        }

        const refreshResponse = await window.fetch(
          `${process.env.REACT_APP_API_URL}/api/v${version}/marketplace/auth/refresh`,
          {
            headers: new Headers({
              ...headers,
            }),
            method: 'POST',
            credentials: 'include',
          }
        );

        if (refreshResponse.ok) {
          return await fetchAPI(endpoint, { ...options, refreshed: true });
        } else {
          showSessionTimeOutModal();
        }
      } else {
        showSessionTimeOutModal();
      }

      if (!window.location.pathname.startsWith('/login')) {
        if (window.localStorage.getItem('authPopup') !== '1') {
          window.localStorage.setItem('authPopup', '1');
          showSessionTimeOutModal();
        }
      }
    }

    throw new HTTPError(json.error_message, json.error_code, json.error_body);
  }

  const contentType = response.headers.get('Content-Type');
  if (includes(downloadableContentTypes, contentType)) {
    const blob = await response.blob();
    return blob;
  }

  let ret;
  try {
    ret = await response.json();
  } catch (e) {
    ret = await response.statusText;
  }

  return ret;
};

function showSessionTimeOutModal() {
  Modal.warning({
    title: translation().form.session_expired,
    content: translation().form.session_expired_desc,
    onOk: () => {
      window.localStorage.clear();
      setTimeout(() => {
        window.localStorage.clear();
      });
      window.location.href =
        '/login?redirect=' + encodeURIComponent(window.location.pathname);
    },
  });
}

export function getUrlParams() {
  return parser.parse(window.location.search);
}

export function getFullAPIURLWithEndpoint(endpoint: string, version: string) {
  return `${process.env.REACT_APP_API_URL}/api/${version}/${endpoint}`;
}

export const downloadFile = (blob: Blob, filename: string = 'download.csv') => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  if (document.body) {
    document.body.appendChild(link);
    link.click();
  }
};

export const fixedBadJson = (data) => {
  const fixedJSON = data
    // Replace ":" with "@colon@" if it's between double-quotes
    .replace(/:\s*"([^"]*)"/g, (match, p1) => {
      return ': "' + p1.replace(/:/g, '@colon@') + '"';
    })
    // Replace ":" with "@colon@" if it's between single-quotes
    .replace(/:\s*'([^']*)'/g, (match, p1) => {
      return ': "' + p1.replace(/:/g, '@colon@') + '"';
    })
    // Add double-quotes around any tokens before the remaining ":"
    .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')
    // Turn "@colon@" back into ":"
    .replace(/@colon@/g, ':');

  return fixedJSON;
};
