import * as LayoutAction from '../actions/LayoutAction';
import { ActionType, getType } from 'typesafe-actions';

const defaultState = {
  managerCollapse: false,
  shouldFetchIncomingRequest: true,
};

export default function layout(
  state: Layout = defaultState,
  action: ActionType<typeof LayoutAction>
): Layout {
  switch (action.type) {
    case getType(LayoutAction.collapseManagerSider):
      return { ...state, managerCollapse: action.payload };
    case getType(LayoutAction.layoutToggleField):
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
