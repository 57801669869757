import * as queryString from 'query-string';
import * as React from 'react';
import { changeLocale as changeLocaleAction } from '../actions/LocaleAction';
import { ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { getFormats, getLocaleMessage } from '../utils/intl';
import { IntlProvider } from 'react-intl';
import { languages } from '../constants/CONSTANTS';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/vi';
import '@formatjs/intl-pluralrules/dist/locale-data/zh';

const mapStateToProps = (state: RootState) => ({
  locale: state.locale,
});
const mapDispatchToProps = {
  changeLocale: changeLocaleAction,
};

type MapDispatchToProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

class ConnectedLocale extends React.Component<StateProps & MapDispatchToProps> {
  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    const { lang } = parsed;
    if (languages.indexOf(lang as LocaleType) > -1) {
      this.props.changeLocale(lang as LocaleType);
    }
  }
  render() {
    const { locale } = this.props;
    const { localMsg, antdMsg } = getLocaleMessage(locale.currentLocale);
    return (
      <ConfigProvider locale={antdMsg}>
        <IntlProvider
          locale={locale.currentLocale}
          key={locale.currentLocale}
          messages={localMsg}
          formats={getFormats()}
        >
          {this.props.children}
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect<StateProps, MapDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLocale);
