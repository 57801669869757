import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { USER_ROLE } from '../constants/CONSTANTS';

export enum DetailURLPrefix {
  product = 'p',
  category = 'c',
  tradeRequest = 'tq',
}

export const loginRequired = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: (state: RootState) => state.user.id != null,
  // A nice display name for this check
  wrapperDisplayName: 'LoginRequired',
});

export const staffRequired = connectedRouterRedirect({
  redirectPath: '/', // FIXME: show 404 page instead
  authenticatedSelector: (state: RootState) =>
    [USER_ROLE.ADMIN.toString(), USER_ROLE.STAFF.toString()].includes(
      state.user.role_id
    ),
  wrapperDisplayName: 'StaffRequired',
});

const locationHelper = locationHelperBuilder({});

export const ifLoggedInRedirect = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps: any) => {
    const redirectUrl = locationHelper.getRedirectQueryParam(ownProps);
    // if (state.user.company != null) {
    //   if (redirectUrl === URL_COMPANY_NEW) {
    //     redirectUrl = URL_MANAGE;
    //   }
    // }
    return redirectUrl || '/';
  },
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state: RootState) => state.user.id === null,
  // A nice display name for this check
  wrapperDisplayName: 'IfLoggedInRedirect',
});

// Add on routes that can only be accessed if you are not a member of any company.
// export const ifCompanyMemberRedirect = connectedRouterRedirect({
//   redirectPath: URL_MANAGE,
//   allowRedirectBack: false,
//   authenticatedSelector: (state: RootState) =>
//     state.user.id !== null && state.user.company === null,
//   wrapperDisplayName: 'IfCompanyMemberRedirect',
// });

export const getPathWithLevel = (path: string, level: number) =>
  path.split('/').slice(0, level).join('/');

export const getMatchId = (matchParams: string) => {
  const names = matchParams.split('_');
  const matchId = names[names.length - 1].slice(1);
  return matchId;
};
