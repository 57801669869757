import { setConfig } from 'actions/ConfigAction';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const CompanyProvider = ({ children }) => {
  const dispatch = useDispatch();
  const getCompany = async () => {
    try {
      const url =
        'https://seller.sourcesage.co/dbs/api/v2.0/storefront/company?domain_name=sourcesage.co';
      const res = await fetch(url);
      const { data } = await res.json();
      const info = {
        logo: data?.company?.logo_url,
        menuLeft: data?.header?.content?.web?.left ?? [],
        menuRight: data?.header?.content?.web?.right ?? [],
        template_id:
          data?.storefront?.dashboard_template_config?.product_listing
            ?.template_id ?? 1,
      };
      dispatch(setConfig({ ...info }));
    } catch (error) {}
  };
  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default CompanyProvider;
