import TopBarProgress from 'components/TopProgress';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';

const DelayedLoading = ({ delay }) => {
  const [ready, setReady] = useState(false);
  useEffectOnce(() => {
    setTimeout(() => setReady(true), delay);
  });
  return ready && <TopBarProgress />;
};

const Loadable = (Component: any) => (props: any) => (
  <React.Suspense fallback={<DelayedLoading delay={300} />}>
    <Component {...props} />
  </React.Suspense>
);

// Blog
export const BlogPage = Loadable(
  React.lazy(() => import('./pages/Blog/BlogPage'))
);

// Homepage
export const Homepage = Loadable(React.lazy(() => import('./pages/Homepage')));
// ProductPage
export const ProductPage = Loadable(
  React.lazy(() => import('./pages/ProductPage/ProductPage'))
);

// Category
export const CategoryPage = Loadable(
  React.lazy(() => import('./pages/Category/CategoryPage'))
);

// Blog
export const PropositionPage = Loadable(
  React.lazy(() => import('./pages/Proposition/PropositionPage'))
);

// Error Pages
export const Page404 = Loadable(
  React.lazy(() => import('./pages/ErrorPage/Page404'))
);
export const Page403 = Loadable(
  React.lazy(() => import('./pages/ErrorPage/Page403'))
);

export const PunchOutPage = Loadable(
  React.lazy(() => import('./pages/PunchPage/PunchoutPage'))
);
export const PunchInPage = Loadable(
  React.lazy(() => import('./pages/PunchPage/PunchInPage'))
);
