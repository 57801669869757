import React from 'react';
import styled from 'styled-components';
import { Dropdown, Icon, Menu } from 'antd';

import { LinkContainer } from './MobileLink';

type LinkT = {
  link: string;
  id: number | string;
  children?: LinkT[];
  name: string;
  prevIcon: string;
  afterIcon: string;
};

type Props = {
  link: LinkT;
};

const WebLinkDropdown = (props: Props) => {
  const { link } = props;

  return (
    <Wrapper>
      <Dropdown
        overlay={
          <Menu>
            {link.children.map((child) => {
              return (
                <Menu.Item key={child.id}>
                  <LinkContainer url={child.link}>
                    <DropdownLinkContent link={child} />
                  </LinkContainer>
                </Menu.Item>
              );
            })}
          </Menu>
        }
      >
        <a
          href="/"
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          {link.prevIcon && <Icon type={link.prevIcon} />}
          <p>{link.name}</p>
          {link.afterIcon && <Icon type={link.afterIcon} />}
        </a>
      </Dropdown>
    </Wrapper>
  );
};

const DropdownLinkContent = ({ link }) => {
  if (link.type === 'button') {
    return (
      <SubMenuBtn bgColor={link.bgColor} textColor={link.btnTextColor}>
        <p>
          {link.prevIcon && <Icon type={link.prevIcon} className="pre" />}
          {link.name}
          {link.afterIcon && <Icon type={link.afterIcon} className="after" />}
        </p>
      </SubMenuBtn>
    );
  } else if (link.type === 'image') {
    return <SubMenuImg src={link.imgUrl} alt={link.name} />;
  } else {
    return (
      <SubMenuText>
        {link.prevIcon && <Icon type={link.prevIcon} />}
        <p>{link.name}</p>
        {link.afterIcon && <Icon type={link.afterIcon} />}
      </SubMenuText>
    );
  }
};

const Wrapper = styled.li`
  > a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555555;

    p {
      margin: 0;
    }

    i:nth-child(1) {
      margin: 0 3px 0 0;
    }

    i:not(:nth-child(1)) {
      margin: 0 0 0 3px;
    }

    &:hover {
      color: var(--ss-primary);

      i {
        color: var(--ss-primary);
      }
    }
  }
`;

const SubMenuText = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    margin: 0;
  }

  i:nth-child(1) {
    margin: 0 5px 0 0;
  }

  i:not(:nth-child(1)) {
    margin: 0 0 0 5px;
  }
`;

const SubMenuImg = styled.img`
  height: 30px;
  max-width: 150px;
  object-fit: contain;
`;

const SubMenuBtn = styled.div.attrs((props: any) => ({
  bgColor: props.bgColor || 'var(--ss-primary)',
  textColor: props.textColor || '#ffffff',
}))`
  padding: 5px 5px;
  border-radius: 5px;
  background-color: ${(p) => p.bgColor};
  color: ${(p) => p.textColor};

  p {
    font-size: 14px;
    margin: 0;
    text-align: center;
  }

  i.pre {
    margin: 0 5px 0 0;
  }

  i.after {
    margin: 0 0 0 5px;
  }
`;

export default WebLinkDropdown;
