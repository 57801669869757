import { merge } from 'lodash';
import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import en from './constants/translations/en-US.json';
import vi from './constants/translations/vi-VN.json';
import zh from './constants/translations/zh-CN.json';
import rootReducer from './reducers';

const enhancers = [];
const raven = createRavenMiddleware(Raven);

const middleware = [raven, thunk];
if (process.env.REACT_APP_SENTRY_DSN) {
  Raven.config(process.env.REACT_APP_SENTRY_DSN).install();
}

const saveSubsetFilter = createFilter('user', [
  'id',
  'verified',
  'company',
  'company_member',
  'settings',
  'username',
  'full_name',
]);
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'constants', 'locale', 'config'],
  transforms: [saveSubsetFilter],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middleware),
  ...enhancers
);
const configureStore = (
  initialState: object = {}
): { store: Store<RootState>; persistor: Persistor } => {
  const rawStore = createStore(
    persistedReducer,
    initialState,
    composedEnhancers
  );
  const rawPersistor = persistStore(rawStore);
  return { store: rawStore, persistor: rawPersistor };
};

const { store, persistor } = configureStore();

const trans = {
  en,
  vi: merge({}, en, vi),
  zh: merge({}, en, zh),
};

let state = store.getState();
store.subscribe(() => {
  state = store.getState();
});

export const translation = () => {
  const locale = state.locale.currentLocale;
  return trans[locale];
};

export default { store, persistor };
