import * as LocaleAction from '../actions/LocaleAction';
import { ActionType, getType } from 'typesafe-actions';
import { getAppLocale } from '../utils/intl';
import { getUserLocale } from 'get-user-locale';

const defaultState: Locale = {
  currentLocale: getAppLocale(getUserLocale()) as LocaleType,
};

export default function layout(
  state: Locale = defaultState,
  action: ActionType<typeof LocaleAction>
): Locale {
  switch (action.type) {
    case getType(LocaleAction.changeLocale):
      return { currentLocale: action.payload };
    default:
      return state;
  }
}
