import React from 'react';
import { Icon } from 'antd';

import { ButtonContainer, ImageLink, LinkText } from './styled-collections';

const LinkContent = (props) => {
  const { link, isMobile } = props;

  if (link.type === 'image') {
    return <ImageLink src={link.imgUrl} alt={link.name} />;
  } else if (link.type === 'button') {
    return (
      <ButtonContainer
        bgColor={link.bgColor}
        textColor={link.btnTextColor}
        isMobile={isMobile}
      >
        <div className="btn">
          {link.prevIcon && <Icon type={link.prevIcon} />}
          <p>{link.name}</p>
          {link.afterIcon && <Icon type={link.afterIcon} />}
        </div>
      </ButtonContainer>
    );
  } else {
    return (
      <LinkText>
        {link.prevIcon && <Icon type={link.prevIcon} />}
        <p>{link.name}</p>
        {link.afterIcon && <Icon type={link.afterIcon} />}
      </LinkText>
    );
  }
};

export default LinkContent;
