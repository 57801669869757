import React from 'react';
import { Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';

import {
  ButtonContainer,
  ImageLink,
  LinkText,
  SubMenuSpan,
} from './styled-collections';

export const LinkContainer = (props) => {
  const { url, children } = props;
  const sameSite = url.startsWith(window.location.origin);
  const clientRedirect = url.startsWith('/');

  if (sameSite) {
    const href = '/' + url.split('/').slice(3).join('/');

    return <Link to={href.toLowerCase()}>{children}</Link>;
  } else if (clientRedirect) {
    return <Link to={url.toLowerCase()}>{children}</Link>;
  } else {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
};

const LinkContent = (props) => {
  const { link } = props;

  if (link.type === 'image') {
    return <ImageLink src={link.imgUrl} alt={link.name} />;
  } else if (link.type === 'button') {
    return (
      <ButtonContainer
        bgColor={link.bgColor}
        textColor={link.btnTextColor}
        isMobile
      >
        <div className="btn">
          {link.prevIcon && <Icon type={link.prevIcon} />}
          <p>{link.name}</p>
          {link.afterIcon && <Icon type={link.afterIcon} />}
        </div>
      </ButtonContainer>
    );
  } else {
    return (
      <LinkText isMobile>
        {link.prevIcon && <Icon type={link.prevIcon} />}
        <p>{link.name}</p>
        {link.afterIcon && <Icon type={link.afterIcon} />}
      </LinkText>
    );
  }
};

const OrdinaryLink = (props) => {
  const { link, closeSider } = props;

  return (
    <Menu.Item key={String(link.id)} className={link.type} onClick={closeSider}>
      <LinkContainer url={link.link}>
        <LinkContent link={link} />
      </LinkContainer>
    </Menu.Item>
  );
};

type Props = {
  link: any;
  closeSider: () => void;
};

const MobileLink = (props: Props) => {
  const { link, closeSider } = props;

  if (link.type === 'dropdown') {
    // DROPDOWN
    return (
      <Menu.SubMenu
        key={String(link.id)}
        title={
          <>
            {link.prevIcon && <Icon type={link.prevIcon} />}
            <SubMenuSpan>{link.name}</SubMenuSpan>
          </>
        }
      >
        {link.children.map((child) =>
          OrdinaryLink({ link: child, closeSider })
        )}
      </Menu.SubMenu>
    );
  } else {
    // ORDINARY LINK FOR TEXT AND IMAGE
    return OrdinaryLink({ link, closeSider });
  }
};

export default MobileLink;
