import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import WebLinkGroup from './WebLinkGroup';

const DynamicHeader = () => {
  const config = useSelector((RootState: any) => RootState?.config);
  const [state, setState] = useState({
    logo: null,
    menuLeft: [],
    menuRight: [],
  });

  useEffect(() => {
    setState({ ...config });
  }, [config]);

  return (
    <div style={{ marginTop: 70 }}>
      <Wrapper>
        <div className="nav-container">
          <WebLinkGroup
            className="left-section"
            list={state?.menuLeft}
            companyLogo={state?.logo}
            categories={null}
          />
          <WebLinkGroup
            className="right-section"
            list={state?.menuRight}
            companyLogo={state?.logo}
            categories={null}
          />
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 999;
  background: #ffffff;
  animation: appear 0.5s;
  top: 0px;

  @keyframes appear {
    0% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0px 30px;
    max-width: 1400px;
    margin: 0 auto;
    @media (max-width: 767px) {
      padding: 0px 15px;
    }
  }
`;
export default DynamicHeader;
